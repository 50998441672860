import React, { Component } from "react";

class OutFooter extends Component {
	render() {
		return (
			<p className="powerbby-login">
				©{new Date().getFullYear()} - Powered by{" "}
				<a target="_blank" href="https://www.emarketingeye.com">
					eMarketingEye
				</a>
			</p>
		);
	}
}

export default OutFooter;
