import React, { Component } from "react";
import LevelThree from "../navitem/level.three";

class LevelTwo extends Component {
	state = {
		selected: false,
	};

	navigateToModule = (e, module) => {
		e.preventDefault();
		this.props.navigateToModule(e, module);
	};

	render() {
		return (
			<React.Fragment>
				<span className="ntoggle">
					<i className="fa fa-angle-down" aria-hidden="true"></i>
				</span>
				<ul className="sub-menu">
					{Object.keys(this.props.levelTwo).map((key1) => (
						<li
							className={
								this.props.moduleOrder.includes(
									this.props.levelTwo[key1]["id"]
								)
									? "li-item-sub subactive"
									: "li-item-sub"
							}
							key={key1}
						>
							<a
								onClick={(e) =>
									this.navigateToModule(
										e,
										this.props.levelTwo[key1]["slug"]
									)
								}
								href="#"
							>
								{this.props.levelTwo[key1]["title"]}
							</a>
							{this.props.levelTwo[key1]["childs"].length ? (
								<LevelThree
									navigateToModule={this.navigateToModule}
									levelThree={
										this.props.levelTwo[key1]["childs"]
									}
									moduleOrder={this.props.moduleOrder}
								></LevelThree>
							) : null}
						</li>
					))}
				</ul>
			</React.Fragment>
		);
	}
}

export default LevelTwo;
