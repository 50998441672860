import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Login from "./components/login";
import AppList from "./components/appList";
import Application from "./components/application";
import { ProtectedRoute } from "./helpers/protected.route";
import { LoginRoute } from "./helpers/login.route";

class App extends Component {
	state = {};
	render() {
		return (
			<React.Fragment>
				<BrowserRouter>
					<Switch>
						<LoginRoute exact path="/login" component={Login} />
						<ProtectedRoute exact path="/" component={AppList} />
						<ProtectedRoute
							exact
							path="/app/:slug/:version?/:module?"
							component={Application}
						/>
					</Switch>
				</BrowserRouter>
			</React.Fragment>
		);
	}
}

export default App;
