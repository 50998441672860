import React, { Component } from "react";
import LevelTwo from "../components/navitem/level.two";
import { BulletList } from "react-content-loader";

class NavBar extends Component {
	navigateToModule = (e, module) => {
		e.preventDefault();
		this.props.handleModule(module);
	};

	render() {
		const {
			moduleOrder,
			moduleSlug,
			appLogo,
			modules,
			version,
			versions,
		} = this.props;

		return (
			<header className="header">
				{appLogo ? (
					<div className="logo">
						<img
							style={{ maxWidth: 280 }}
							src={appLogo}
							alt="application_logo"
						/>
					</div>
				) : (
					<div>
						<BulletList
							opacity="0.5"
							foregroundColor="#f0f0f0"
							backgroundColor="#021a37"
						></BulletList>
						<BulletList
							opacity="0.5"
							foregroundColor="#f0f0f0"
							backgroundColor="#021a37"
						></BulletList>
						<BulletList
							opacity="0.5"
							foregroundColor="#f0f0f0"
							backgroundColor="#021a37"
						></BulletList>
						<BulletList
							opacity="0.5"
							foregroundColor="#f0f0f0"
							backgroundColor="#021a37"
						></BulletList>
					</div>
				)}
				<ul className="new-nav">
					{appLogo ? (
						<li
							className={
								moduleSlug == null
									? "li-item active"
									: "li-item"
							}
						>
							<a
								onClick={(e) => this.navigateToModule(e, null)}
								href="#"
							>
								Introduction
							</a>
						</li>
					) : (
						""
					)}

					{Object.keys(modules).map((key) => (
						<li
							className={
								moduleOrder.includes(modules[key]["id"])
									? "li-item active"
									: "li-item"
							}
							key={key}
						>
							<a
								onClick={(e) =>
									this.navigateToModule(
										e,
										modules[key]["slug"]
									)
								}
								href="#"
							>
								{modules[key]["title"]}
							</a>
							{modules[key]["childs"].length ? (
								<LevelTwo
									levelTwo={modules[key]["childs"]}
									navigateToModule={this.navigateToModule}
									moduleSlug={moduleSlug}
									moduleOrder={moduleOrder}
								></LevelTwo>
							) : null}
						</li>
					))}
				</ul>
				<select
					style={{ color: "white" }}
					className="version-drop"
					value={version}
					onChange={this.props.versionChange}
				>
					{versions.map((versionName) => (
						<option key={versionName} value={versionName}>
							{versionName}
						</option>
					))}
				</select>
			</header>
		);
	}
}

export default NavBar;
