import React, { Component } from "react";

class HomeMain extends Component {
	navigateToModule = (e, module) => {
		e.preventDefault();
		this.props.handleModule(module);
	};

	render() {
		const { modules, appSlug } = this.props;
		return (
			<div className="main-content">
				<h1>{this.props.mainData.application_name}</h1>
				<div className="content-wrap">
					<h5>Version : {this.props.mainData.version}</h5>
					{/* <h5>Updated By : {this.props.mainData.updated_by}</h5> */}
					<h4>Release Note</h4>
					<p>{this.props.mainData.version_description}</p>
					<h4>System Description</h4>
					<p>{this.props.mainData.application_description}</p>
					<h4>Content</h4>
					<ul>
						{Object.keys(modules).map((key) => (
							<li key={key}>
								<a
									onClick={(e) =>
										this.navigateToModule(
											e,
											modules[key]["slug"]
										)
									}
									href="#"
								>
									{modules[key]["title"]}
								</a>
								{modules[key]["childs"].length ? (
									<ul>
										{Object.keys(
											modules[key]["childs"]
										).map((key1) => (
											<li key={key1}>
												<a
													onClick={(e) =>
														this.navigateToModule(
															e,
															modules[key][
																"childs"
															][key1]["slug"]
														)
													}
													href="#"
												>
													{
														modules[key]["childs"][
															key1
														]["title"]
													}
												</a>
												{modules[key]["childs"][key1][
													"childs"
												].length ? (
													<ul>
														{Object.keys(
															modules[key][
																"childs"
															][key1]["childs"]
														).map((key2) => (
															<li key={key2}>
																<a
																	onClick={(
																		e
																	) =>
																		this.navigateToModule(
																			e,
																			modules[
																				key
																			][
																				"childs"
																			][
																				key1
																			][
																				"childs"
																			][
																				key2
																			][
																				"slug"
																			]
																		)
																	}
																	href="#"
																>
																	{
																		modules[
																			key
																		][
																			"childs"
																		][key1][
																			"childs"
																		][key2][
																			"title"
																		]
																	}
																</a>
															</li>
														))}
													</ul>
												) : null}
											</li>
										))}
									</ul>
								) : null}
							</li>
						))}
					</ul>
				</div>
				{/* <div className="pagination-wrap">
					<button onClick={this.props.handleNextPage}>
						{" "}
						<i
							className="fa fa-arrow-right"
							aria-hidden="true"
						></i>{" "}
						Next
					</button>
				</div> */}
			</div>
		);
	}
}

export default HomeMain;
