import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

class Logout extends Component {
	state = {
		allowedApps: [],
		errorMsg: null,
		userName: null,
	};

	componentDidMount() {
		const { accessToken, allowedApps, userName } = JSON.parse(
			Cookies.get("ums-data")
		);

		this.setState({ userName });
		axios({
			method: "get",
			url: `${apiUrl}/allowedApps`,
			headers: { Authorization: `Bearer ${accessToken}` },
			params: {
				allowedApps: allowedApps,
			},
		})
			.then((response) => {
				if (typeof response !== "undefined" && response.data) {
					const { error, msg, data } = response.data;
					if (!error) {
						this.setState({ allowedApps: data });
					} else {
						this.setState({ errorMsg: msg });
					}
				} else {
					this.setState({
						errorMsg: "Invalid Response",
					});
				}
			})
			.catch((err) => {
				console.error(err);
				this.setState({
					errorMsg: "Something wrong with the request",
				});
			});
	}

	render() {
		return (
			<div className="logout-wrap">
				<select
					value={this.props.appSlug}
					onChange={this.props.appChange}
				>
					{this.state.allowedApps.map((app) => (
						<option key={app.id} value={app.slug}>
							{app.application_name}
						</option>
					))}
				</select>
				<a>
					<b>{this.state.userName}</b>
				</a>
				<a href="#" onClick={this.props.logoutUser}>
					Logout
				</a>
			</div>
		);
	}
}

export default Logout;
