import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
	<React.StrictMode>
		<GoogleOAuthProvider clientId="273647121555-ribmm75iets2g56m4chb4eijqpflke4s.apps.googleusercontent.com">
			<App />
		</GoogleOAuthProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
