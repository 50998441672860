import React, { Component } from "react";

class LevelThree extends Component {
	state = {
		selected: false,
	};

	navigateToModule = (e, module) => {
		this.props.navigateToModule(e, module);
	};

	render() {
		return (
			<React.Fragment>
				<span className="ntogglesub">
					<i className="fa fa-angle-down" aria-hidden="true"></i>
				</span>
				<ul className="sub-menu2">
					{Object.keys(this.props.levelThree).map((key2) => (
						<li
							className={
								this.props.moduleOrder.includes(
									this.props.levelThree[key2]["id"]
								)
									? "li-item-sub3 active"
									: "li-item-sub3"
							}
							key={key2}
						>
							<a
								onClick={(e) =>
									this.navigateToModule(
										e,
										this.props.levelThree[key2]["slug"]
									)
								}
								href="#"
							>
								{this.props.levelThree[key2]["title"]}
							</a>
						</li>
					))}
				</ul>
			</React.Fragment>
		);
	}
}

export default LevelThree;
