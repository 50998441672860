import React, { Component } from "react";

class Module extends Component {
	state = {};
	render() {
		return (
			<div className="main-content">
				{/* <div className="breadcrumb-wrap">
					<ul>
						<li>
							<a href="">Home</a>
						</li>
					</ul>
				</div> */}
				<h1>{this.props.moduleData.title}</h1>
				<div
					className="content-wrap"
					dangerouslySetInnerHTML={{
						__html: this.props.moduleData.content,
					}}
				/>

				<div className="pagination-wrap">
					{this.props.prevPage !== null ? (
						<button onClick={this.props.handlePrevPage}>
							<i
								className="fa fa-arrow-left"
								aria-hidden="true"
							></i>{" "}
							Prev
						</button>
					) : null}

					{this.props.nextPage !== null ? (
						<button onClick={this.props.handleNextPage}>
							Next{" "}
							<i
								className="fa fa-arrow-right"
								aria-hidden="true"
							></i>
						</button>
					) : null}
				</div>
			</div>
		);
	}
}

export default Module;
