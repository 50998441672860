import React, { Component } from "react";

class InFooter extends Component {
	render() {
		return (
			<footer className="footer">
				<div className="copyright">
					{" "}
					©{new Date().getFullYear()} - Powered by{" "}
					<a target="_blank" href="https://www.emarketingeye.com">
						eMarketingEye
					</a>
				</div>
			</footer>
		);
	}
}

export default InFooter;
