import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				const appSlug =
					typeof props.match.params.slug !== "undefined"
						? props.match.params.slug
						: null;

				if (auth.isAuthenticated() && auth.isAllowedApp(appSlug)) {
					return <Component {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: props.location },
							}}
						/>
					);
				}
			}}
		/>
	);
};
