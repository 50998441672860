import Cookies from "js-cookie";

class Auth {
	constructor() {
		this.authData =
			typeof Cookies.get("ums-data") !== "undefined"
				? JSON.parse(Cookies.get("ums-data"))
				: null;
		this.authenticated =
			this.authData !== null ? this.authData.isAuthenticated : false;
	}

	logout = () => {
		this.authenticated = false;
		Cookies.remove("ums-data");

		return true;
	};

	isAuthenticated() {
		return this.authenticated;
	}

	isAllowedApp(appSlug) {
		let isAllowed = true;

		if (appSlug == null) {
			return isAllowed;
		}

		if (
			this.authData !== null &&
			typeof this.authData.allowedSlugs !== "undefined"
		) {
			if (!this.authData.allowedSlugs.includes(appSlug))
				isAllowed = false;
		}

		return isAllowed;
	}
}

export default new Auth();
