import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import OutFooter from "../components/out.footer";
import { motion } from "framer-motion";

const apiUrl = process.env.REACT_APP_API_URL;

class AppList extends Component {
	state = {
		allowedApps: [],
		errorMsg: null,
		userName: null,
	};

	componentDidMount() {
		const { accessToken, allowedApps, userName } = JSON.parse(
			Cookies.get("ums-data")
		);

		this.state.userName = userName;

		axios({
			method: "get",
			url: `${apiUrl}/allowedApps`,
			headers: { Authorization: `Bearer ${accessToken}` },
			params: {
				allowedApps: allowedApps,
			},
		})
			.then((response) => {
				if (typeof response !== "undefined" && response.data) {
					const { error, msg, data } = response.data;
					if (!error) {
						this.setState({ allowedApps: data });
					} else {
						this.setState({ errorMsg: msg });
					}
				} else {
					this.setState({
						errorMsg: "Invalid Response",
					});
				}
			})
			.catch((err) => {
				console.error(err);
				this.setState({
					errorMsg: "Something wrong with the request",
				});
			});
	}

	userLogout = () => {
		Cookies.remove("ums-data");
		window.location.href = "/login";
	};

	render() {
		return (
			<React.Fragment>
				<motion.div
					className="apps-wrap"
					initial={{ opacity: 0 }}
					animate={{ x: 0, y: 0, opacity: 1, rotate: 0 }}
					exit={{ opacity: 0 }}
					transition={{
						duration: 1,
					}}
				>
					<h4>Select Product to Continue</h4>
					{this.state.errorMsg ? (
						<div>{this.state.errorMsg}</div>
					) : (
						<ul>
							{this.state.allowedApps.map((app) => (
								<li key={app.id}>
									<a
										style={{ margin: 10 }}
										href={`/app/${app.slug}`}
									>
										<img src={app.logo_url}></img>
									</a>
								</li>
							))}
						</ul>
					)}
				</motion.div>
				<OutFooter></OutFooter>
				<motion.div
					className="logout-wrap"
					initial={{ opacity: 0 }}
					animate={{ x: 0, y: 0, opacity: 1, rotate: 0 }}
					exit={{ opacity: 0 }}
					transition={{
						duration: 1,
					}}
				>
					<a>
						<b>{this.state.userName}</b>
					</a>
					<a href="#" onClick={this.userLogout}>
						Logout
					</a>
				</motion.div>
			</React.Fragment>
		);
	}
}

export default AppList;
