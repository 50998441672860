import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import OutFooter from "../components/out.footer";
import { motion } from "framer-motion";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL;

class Login extends Component {
	state = {
		userEmail: null,
		errorMsg: null,
	};
	responseGoogle = (response) => {
		const userObject = jwt_decode(response.credential);
		const { email } = userObject;
		this.setState({ userEmail: email });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.userEmail !== this.state.userEmail) {
			axios({
				method: "post",
				url: `${apiUrl}/login`,
				data: {
					user_email: this.state.userEmail,
				},
			})
				.then((response) => {
					if (typeof response !== "undefined" && response.data) {
						const { error, msg, data } = response.data;
						if (!error) {
							let date = new Date();
							date.setTime(date.getTime() + 3660 * 1000);
							Cookies.set("ums-data", JSON.stringify(data), {
								expires: date,
							});

							if (typeof this.props.location.state != 'undefined'
								&& typeof this.props.location.state.from != 'undefined'
								&& typeof this.props.location.state.from.pathname != 'undefined'
							) {
								window.location = this.props.location.state.from.pathname;
							} else {
								window.location.reload();
							}
						} else {
							this.setState({ errorMsg: msg });
						}
					} else {
						this.setState({
							errorMsg: "Invalid Response",
						});
					}
				})
				.catch((err) => {
					console.error(err);
					this.setState({
						errorMsg: "Something wrong with the request",
					});
				});
		}
	}

	render() {
		return (
			<div>
				<motion.div
					className="login-wrap"
					initial={{ scale: 0 }}
					animate={{ x: 0, y: 0, scale: 1, rotate: 0 }}
					transition={{
						type: "spring",
						stiffness: 200,
						damping: 20,
					}}
				>
					<img src="/assets/img/logo.png" alt="" />
					<h1>User Manual System</h1>
					<h4>Sign in</h4>
					<br></br>
					{this.state.errorMsg ? (
						<div>{this.state.errorMsg}</div>
					) : null}
					<div style={{display: "flex", justifyContent: "center"}}>
						<GoogleLogin
						  onSuccess={this.responseGoogle}
						  onError={() => {
						    console.log('Login Failed');
						  }}
						  cookiePolicy="single_host_origin"
						/>
					</div>
				</motion.div>
				<OutFooter></OutFooter>
			</div>
		);
	}
}

export default Login;
