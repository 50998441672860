import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import HomeMain from "../components/home.main";
import NavBar from "../components/navbar";
import Module from "../components/module";
import Logout from "../components/logout";
import InFooter from "../components/in.footer";
import List from "react-content-loader";

const apiUrl = process.env.REACT_APP_API_URL;
const { accessToken } =
	typeof Cookies.get("ums-data") !== "undefined"
		? JSON.parse(Cookies.get("ums-data"))
		: { accessToken: null };

class Home extends Component {
	state = {
		modules: {},
		homePageData: {},
		appSlug: this.props.match.params.slug,
		versions: [],
		version:
			typeof this.props.match.params.version !== "undefined"
				? this.props.match.params.version
				: null,
		nextModule: null,
		prevModule: null,
		isModule:
			typeof this.props.match.params.module !== "undefined"
				? true
				: false,
		module:
			typeof this.props.match.params.module !== "undefined"
				? this.props.match.params.module
				: null,
		appLogo: null,
		moduleData: {},
		errorMsg: null,
		isLoading: true,
		isNavLoading: true,
		moduleOrder: {},
	};

	handleModule = (selectedModule) => {
		this.setState(
			{
				module: selectedModule,
				isModule: selectedModule == null ? false : true,
			},
			() => {
				const { appSlug, version, module, isModule } = this.state;
				let changeUrl = null;

				if (isModule) {
					changeUrl = `/app/${appSlug}/${version}/${module}`;
				} else {
					changeUrl = `/app/${appSlug}/${version}`;
				}

				this.props.history.push(changeUrl);
			}
		);
	};

	versionChange = (event) => {
		const selectedVersion = event.target.value;
		this.setState({ version: selectedVersion }, () => {
			const { appSlug, version } = this.state;
			this.props.history.push(`/app/${appSlug}/${version}`);
		});
	};

	appChange = (event) => {
		const selectedApp = event.target.value;
		this.setState({ appSlug: selectedApp, version: null }, () => {
			const { appSlug } = this.state;
			this.props.history.push(`/app/${appSlug}`);
		});
	};

	logoutUser = () => {
		Cookies.remove("ums-data");
		window.location.href = "/login";
	};

	componentDidMount() {
		if (this.state.isModule) {
			this.getModuleData();
		} else {
			this.getMainPageData();
		}
	}

	handleNextPage = () => {
		this.handleModule(this.state.nextModule);
	};

	handlePrevPage = () => {
		this.handleModule(this.state.prevModule);
	};

	componentDidUpdate(prevProps, prevState) {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});

		if (prevState.version !== this.state.version) {
			this.setState({ isModule: false }, () => {
				this.getMainPageData();
			});
		}

		if (prevState.appSlug !== this.state.appSlug) {
			this.setState({ isModule: false }, () => {
				this.getMainPageData();
			});
		}

		if (prevState.module !== this.state.module) {
			this.state.module == null
				? this.getMainPageData()
				: this.getModuleData();
		}
	}

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
	}

	getModuleData = () => {
		this.setState({ isLoading: true });
		const { version, module } = this.state;
		axios({
			method: "get",
			url: `${apiUrl}/module`,
			headers: { Authorization: `Bearer ${accessToken}` },
			params: {
				appSlug: this.state.appSlug,
				version: version,
				moduleSlug: module,
			},
		})
			.then((response) => {
				if (typeof response !== "undefined" && response.data) {
					const { error, msg, data } = response.data;
					if (!error) {
						const {
							navBarData,
							moduleData,
							moduleStructure,
							prevNextModules,
						} = data;

						const moduleOrder = {};
						Object.keys(moduleStructure).map((key1) => {
							let level1 = moduleStructure[key1]["slug"];
							moduleOrder[level1] = [moduleStructure[key1]["id"]];
							if (moduleStructure[key1]["childs"].length) {
								Object.keys(
									moduleStructure[key1]["childs"]
								).map((key2) => {
									let level2 =
										moduleStructure[key1]["childs"][key2][
											"slug"
										];
									moduleOrder[level2] = [
										moduleStructure[key1]["childs"][key2][
											"id"
										],
										moduleStructure[key1]["id"],
									];
									if (
										moduleStructure[key1]["childs"][key2][
											"childs"
										].length
									) {
										Object.keys(
											moduleStructure[key1]["childs"][
												key2
											]["childs"]
										).map((key3) => {
											let level3 =
												moduleStructure[key1]["childs"][
													key2
												]["childs"][key3]["slug"];
											moduleOrder[level3] = [
												moduleStructure[key1]["childs"][
													key2
												]["childs"][key3]["id"],
												moduleStructure[key1]["childs"][
													key2
												]["id"],
												moduleStructure[key1]["id"],
											];
										});
									}
								});
							}
						});

						this.setState({
							modules: moduleStructure,
							versions: navBarData.versions,
							version: navBarData.version,
							appLogo: navBarData.application_logo,
							moduleData: moduleData,
							nextModule: prevNextModules.next,
							prevModule: prevNextModules.prev,
							moduleOrder: moduleOrder,
						});
					} else {
						this.setState({ errorMsg: msg });
					}
				} else {
					this.setState({
						errorMsg: "Invalid Response",
					});
				}
				this.setState({ isLoading: false, isNavLoading: false });
			})
			.catch((err) => {
				console.error(err);
				this.setState({
					errorMsg: "Something wrong with the request",
				});
			});
	};

	getMainPageData = () => {
		this.setState({ isLoading: true, module: null });
		const currentVersion = this.state.version;
		axios({
			method: "get",
			url: `${apiUrl}/homePage`,
			headers: { Authorization: `Bearer ${accessToken}` },
			params: {
				appSlug: this.state.appSlug,
				version: currentVersion,
			},
		})
			.then((response) => {
				if (typeof response !== "undefined" && response.data) {
					const { error, msg, data } = response.data;
					if (!error) {
						const { homePageData, moduleStructure } = data;
						const [nexModule] = moduleStructure;
						this.setState({
							modules: moduleStructure,
							homePageData,
							versions: homePageData.versions,
							version: homePageData.version,
							appLogo: homePageData.application_logo,
							nextModule:
								typeof nexModule !== "undefined"
									? nexModule.slug
									: null,
						});
					} else {
						this.setState({ errorMsg: msg });
					}
				} else {
					this.setState({
						errorMsg: "Invalid Response",
					});
				}
				this.setState({ isLoading: false, isNavLoading: false });
			})
			.catch((err) => {
				console.error(err);
				this.setState({
					errorMsg: "Something wrong with the request",
				});
			});
	};

	render() {
		if (this.state.errorMsg) {
			return <div>{this.state.errorMsg}</div>;
		}
		const { modules, homePageData } = this.state;
		return (
			<React.Fragment>
				<NavBar
					moduleSlug={this.state.module}
					modules={modules}
					appLogo={this.state.appLogo}
					versions={this.state.versions}
					version={this.state.version}
					handleModule={this.handleModule}
					versionChange={this.versionChange}
					moduleOrder={
						typeof this.state.moduleOrder[this.state.module] !==
						"undefined"
							? this.state.moduleOrder[this.state.module]
							: []
					}
				/>
				{this.state.isModule ? (
					this.state.isLoading ? (
						<div className="main-content">
							<List></List>
							<List></List>
						</div>
					) : (
						<Module
							moduleData={this.state.moduleData}
							nextPage={this.state.nextModule}
							prevPage={this.state.prevModule}
							handleNextPage={this.handleNextPage}
							handlePrevPage={this.handlePrevPage}
						/>
					)
				) : this.state.isLoading ? (
					<div className="main-content">
						<List></List>
						<List></List>
					</div>
				) : (
					<HomeMain
						handleModule={this.handleModule}
						mainData={homePageData}
						modules={modules}
						appSlug={this.state.appSlug}
						// nextPage={this.state.nextModule}
						// handleNextPage={this.handleNextPage}
					/>
				)}
				<Logout
					appChange={this.appChange}
					appSlug={this.state.appSlug}
					logoutUser={this.logoutUser}
				/>
				<InFooter></InFooter>
			</React.Fragment>
		);
	}
}

export default Home;
